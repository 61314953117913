<template>
  <CustomCard
    title="Editeurs"
    @click="
      (showAddStatusModal = true), (name = ''), (description = ''), (type = '')
    "
    @change="filterTypes"
  >
    <template v-slot:table>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
        responsive
        show-empty
        striped
        bordered
        id="targeted-table"
        class="mb-auto col-12 p-0"
        :busy="loading"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center bg-white p-0 text-gray">Table vide</div>
        </template>
        <template #cell(name)="data">
          <div v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value }}
          </div>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.name.$error && $v.name.$dirty,
              }"
              placeholder="Nom"
              type="text"
              v-model="name"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.name.$dirty"
            >
              {{ !$v.name.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <!-- <template v-slot:cell(@type)="data">
          <span> {{ data.value.slice(4) || "-" }}</span>
        </template> -->
        <template #cell(description)="data">
          <div v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value || "-" }}
          </div>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.description.$error && $v.description.$dirty,
              }"
              placeholder="Description"
              type="text"
              v-model="description"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.description.$dirty"
            >
              {{ !$v.description.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template #cell(actions)="data">
          <table-actions
            class="justify-content-end"
            :actions="['edit', 'delete']"
            :editMode="data.item.hasOwnProperty('editMode')"
            @editItem="editItem(data.item)"
            @deleteItem="deleteItem(data.item.id)"
            @cancelItem="cancelItem(data.item)"
            @confirmItem="confirmItem(data.item)"
          ></table-actions>
        </template>
      </b-table>
    </template>
    <template v-slot:pagination>
      <div class="ml-auto mt-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
          class="p-0 mt-3 mb-0"
        >
        </b-pagination>
      </div>
      <!-- MODAL -->
      <div to="modals-xyz-548" v-if="showAddStatusModal">
        <modal @close="showAddStatusModal = !showAddStatusModal">
          <div class="row" style="margin-top: -56px">
            <div class="col-8">
              <p class="h2 text-secondary">
                <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

                <span style="font-size: 18px" class="font-weight-light ml-1"
                  >Nouveau ligne métier
                </span>
              </p>
            </div>
            <b-button
              pill
              @click="addType"
              size="sm"
              variant="primary"
              style="width: 40px; height: 40px; margin-right: 65px"
              class="d-flex align-items-center justify-content-center ml-auto"
              ><i class="mdi mdi-check"></i
            ></b-button>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Nom:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-input
                  class="bg-transparent"
                  label="text"
                  placeholder="Nom"
                  v-model="name"
                  :class="{
                    'is-invalid': $v.name.$error && $v.name.$dirty,
                  }"
                >
                </b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.name.$dirty"
                >
                  {{ !$v.name.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Description:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-textarea
                  class="bg-transparent"
                  label="text"
                  placeholder="Description"
                  v-model="description"
                  :class="{
                    'is-invalid':
                      $v.description.$error && $v.description.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </b-form-textarea>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.description.$dirty"
                >
                  {{ !$v.description.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </modal>
      </div>
    </template>
  </CustomCard>
</template>

<script>
import CustomCard from "../../components/CustomCard.vue";
import { mapGetters } from "vuex";
import TableActions from "@/components/custom/TableActions";
import Swal from "sweetalert2";
import Modal from "@/components/custom/Modal.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    CustomCard,
    TableActions,
    Modal,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "description", label: "Description", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
          sortable: false,
        },
      ],
      items: [],
      perPage: 5,
      currentPage: 1,
      loading: true,
      name: "",
      description: "",
      showAddStatusModal: false,
    };
  },
  validations: {
    name: { required },
    description: { required },
  },
  methods: {
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("editeur/deleteEditeur", id).then(() => {
            this.$store.dispatch("editeur/fetchAllEditeurs").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
    addType() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(`editeur/createEditeur`, {
            name: this.name,
            description: this.description,
          })
          .then(() => {
            this.showAddStatusModal = false;
            this.$store.dispatch("editeur/fetchAllEditeurs").then(() => {
              Swal.fire("La ligne métier est bien créé !", "", "success");

              this.name = "";
              this.description = "";
              this.$v.$reset();
            });
          });
      }
    },
    editItem(item) {
      this.$store.dispatch("editeur/fetchAllEditeurs").then(() => {
        this.items = this.items.map((name) =>
          item.id === name.id ? { ...name, editMode: true } : name
        );
        this.name = item.name;
        this.description = item.description;
      });
    },
    cancelItem() {
      if (this.name === "" && this.description === "") {
        this.$store.dispatch("editeur/fetchAllEditeurs");
        this.$v.$reset();
      } else this.name = this.description = "";
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch(`editeur/updateEditeur`, {
            id: item.id,
            name: this.name,
            description: this.description,
          })
          .then(() => {
            this.$store.dispatch("editeur/fetchAllEditeurs").then(() => {
              Swal.fire("La ligne métier est mis à jour !", "", "success");
              this.name = this.description = "";
              this.$v.$reset();
            });
          });
      }
    },
    filterTypes(value) {
      this.items = this.EDITEURS.filter(
        (editeur) =>
          (editeur.name
            ? editeur.name.toLowerCase().includes(value.toLowerCase())
            : false) ||
          (editeur.description
            ? editeur.description.toLowerCase().includes(value.toLowerCase())
            : false)
      );
    },
  },
  computed: {
    ...mapGetters("editeur", ["EDITEURS"]),

    rows() {
      return this.items.length;
    },
  },
  created() {
    this.$store.dispatch("editeur/fetchAllEditeurs").then(() => {
      this.loading = false;
    });
  },
  watch: {
    EDITEURS() {
      this.items = this.EDITEURS;
    },
  },
};
</script>
