<template>
  <CustomCard
    title="Devises"
    @click="
      (showAddDeviseModal = true), (symbole = ''), (name = ''), (code = '')
    "
    @change="filterProtee"
  >
    <template v-slot:table>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
        responsive
        show-empty
        striped
        bordered
        id="targeted-table"
        class="mb-auto col-12 p-0"
        :busy="loading"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center bg-white p-0 text-gray">Table vide</div>
        </template>
        <template v-slot:cell(name)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value || "-" }}</span
          >
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.name.$error && $v.name.$dirty,
              }"
              placeholder="Nom"
              type="text"
              v-model="name"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.name.$dirty"
            >
              {{ !$v.name.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template v-slot:cell(symbole)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value || "-" }}</span
          >
          <div v-else>
            <b-form-input placeholder="Symbole" type="text" v-model="symbole" />
          </div>
        </template>
        <template v-slot:cell(code)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value }}</span
          >
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.code.$error && $v.code.$dirty,
              }"
              placeholder="Code"
              type="text"
              v-model="code"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.code.$dirty"
            >
              {{ !$v.code.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template #cell(actions)="data">
          <table-actions
            class="justify-content-end"
            :actions="['edit', 'delete']"
            @editItem="editItem(data.item)"
            :editMode="data.item.hasOwnProperty('editMode')"
            @deleteItem="deleteItem(data.item.id)"
            @cancelItem="cancelItem(data.item)"
            @confirmItem="confirmItem(data.item)"
          ></table-actions> </template
      ></b-table>
    </template>
    <template v-slot:pagination>
      <div class="ml-auto mt-auto">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          aria-controls="table-list"
          align="right"
          class="p-0 mt-3 mb-0"
        >
        </b-pagination>
      </div>
      <div to="modals-xyz-548" v-if="showAddDeviseModal">
        <modal @close="showAddDeviseModal = !showAddDeviseModal">
          <div class="row" style="margin-top: -56px">
            <div class="col-8">
              <p class="h2 text-secondary">
                <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

                <span style="font-size: 18px" class="font-weight-light ml-1"
                  >Nouvelle devise
                </span>
              </p>
            </div>
            <b-button
              pill
              @click="addPort"
              size="sm"
              variant="primary"
              style="width: 40px; height: 40px; margin-right: 65px"
              class="d-flex align-items-center justify-content-center ml-auto"
              ><i class="mdi mdi-check"></i
            ></b-button>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Nom:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-input
                  class="bg-transparent"
                  label="text"
                  placeholder="Nom"
                  v-model="name"
                  :class="{
                    'is-invalid': $v.name.$error && $v.name.$dirty,
                  }"
                >
                </b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.name.$dirty"
                >
                  {{ !$v.name.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Code:"
                label-cols-sm="4"
                label-align-sm="left"
                id="add-page"
              >
                <b-form-input
                  class="bg-transparent"
                  label="text"
                  placeholder="Code"
                  v-model="code"
                  :class="{
                    'is-invalid': $v.code.$error && $v.code.$dirty,
                  }"
                >
                </b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.code.$dirty"
                >
                  {{ !$v.code.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Symbole:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-textarea
                  class="bg-transparent"
                  label="text"
                  placeholder="Symbole"
                  v-model="symbole"
                >
                  <template #no-options> Liste vide </template>
                </b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </modal>
      </div>
    </template>
  </CustomCard>
</template>

<script>
import CustomCard from "../../components/CustomCard.vue";
import TableActions from "@/components/custom/TableActions";
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    CustomCard,
    TableActions,
    Modal,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "symbole", label: "Symbole", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
          sortable: false,
        },
      ],
      items: [],
      perPage: 5,
      currentPage: 1,
      loading: true,
      name: "",
      symbole: "",
      code: "",
      showAddDeviseModal: false,
    };
  },
  validations: {
    name: { required },
    // symbole: { required },
    code: { required },
  },
  methods: {
    addPort() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("currency/createCurrency", {
            name: this.name,
            symbole: this.symbole,
            code: this.code,
          })
          .then(() => {
            this.showAddDeviseModal = false;
            this.$store.dispatch("currency/fetchAllCurrencies").then(() => {
              this.name = "";
              this.code = "";
              this.symbole = "";
              Swal.fire("La devise est bien créée !", "", "success");
            });
          });
      }
    },
    editItem(item) {
      this.$store.dispatch("currency/fetchAllCurrencies").then(() => {
        this.items = this.items.map((currency) =>
          item.id === currency.id ? { ...currency, editMode: true } : currency
        );
        this.name = item.name;
        this.symbole = item.symbole;
        this.code = item.code;
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("currency/updateCurrency", {
            id: item.id,
            name: this.name,
            symbole: this.symbole,
            code: this.code,
          })
          .then(() => {
            this.$store.dispatch("currency/fetchAllCurrencies").then(() => {
              Swal.fire("La devise est mise à jour !", "", "success");
              this.name = this.symbole = this.code = "";
            });
          });
      }
    },
    cancelItem() {
      if (this.name === "" && this.symbole === "" && this.code === "")
        this.$store.dispatch("currency/fetchAllCurrencies");
      else this.name = this.symbole = this.code = "";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("currency/deleteCurrency", id).then(() => {
            this.$store.dispatch("currency/fetchAllCurrencies");
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
    filterProtee(value) {
      this.items = this.PORTES.filter(
        (status) =>
          status.name.toLowerCase().includes(value.toLowerCase()) ||
          status.symbole.toLowerCase().includes(value.toLowerCase()) ||
          status["@type"].toLowerCase().includes(value.toLowerCase())
      );
    },
  },
  computed: {
    ...mapGetters("currency", ["CURRENCIES"]),
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.$store.dispatch("currency/fetchAllCurrencies").then(() => {
      this.loading = false;
    });
  },
  watch: {
    CURRENCIES() {
      this.items = this.CURRENCIES;
    },
  },
};
</script>
