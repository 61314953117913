<template>
  <CustomCard
    title="Portées"
    @click="
      (showAddPortModal = true), (type = ''), (name = ''), (description = '')
    "
    @change="filterProtee"
  >
    <template v-slot:table>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
        responsive
        show-empty
        striped
        bordered
        id="targeted-table"
        :busy="loading"
        class="mb-auto col-12 p-0"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center bg-white p-0 text-gray">Table vide</div>
        </template>
        <template v-slot:cell(@type)="data">
          <span> {{ data.value.slice(5) || "-" }}</span>
        </template>

        <template #cell(name)="data">
          <div v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value }}
          </div>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.name.$error && $v.name.$dirty,
              }"
              placeholder="Nom"
              type="text"
              v-model="name"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.name.$dirty"
            >
              {{ !$v.name.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>

        <template #cell(description)="data">
          <div v-if="!data.item.hasOwnProperty('editMode')">
            {{ data.value }}
          </div>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid': $v.description.$error && $v.description.$dirty,
              }"
              placeholder="Description"
              type="text"
              v-model="description"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.description.$dirty"
            >
              {{ !$v.description.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>

        <template #cell(actions)="data">
          <table-actions
            class="justify-content-end"
            :actions="['edit']"
            @editItem="editItem(data.item)"
            :editMode="data.item.hasOwnProperty('editMode')"
            @deleteItem="deleteItem(data.item.id)"
            @cancelItem="cancelItem(data.item)"
            @confirmItem="confirmItem(data.item)"
          ></table-actions>
        </template>
      </b-table>
    </template>
    <template v-slot:pagination>
      <div class="ml-auto mt-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
          class="p-0 mt-3 mb-0"
        >
        </b-pagination>
      </div>
      <div to="modals-xyz-548" v-if="showAddPortModal">
        <modal @close="showAddPortModal = !showAddPortModal">
          <div class="row" style="margin-top: -56px">
            <div class="col-8">
              <p class="h2 text-secondary">
                <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

                <span style="font-size: 18px" class="font-weight-light ml-1"
                  >Nouvelle portée
                </span>
              </p>
            </div>
            <b-button
              pill
              @click="addPort"
              size="sm"
              variant="primary"
              style="width: 40px; height: 40px; margin-right: 65px"
              class="d-flex align-items-center justify-content-center ml-auto"
              ><i class="mdi mdi-check"></i
            ></b-button>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Nom:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-input
                  class="bg-transparent"
                  label="text"
                  placeholder="Nom"
                  v-model="name"
                  :class="{
                    'is-invalid': $v.name.$error && $v.name.$dirty,
                  }"
                >
                </b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.name.$dirty"
                >
                  {{ !$v.name.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Type:"
                label-cols-sm="4"
                label-align-sm="left"
                id="add-page"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid': $v.type.$error && $v.type.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    placeholder="Type"
                    :options="['Application', 'Composant']"
                    v-model="type"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.type.$dirty"
                >
                  {{ !$v.type.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group
                class="font-weight-bold"
                label="Description:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-textarea
                  class="bg-transparent"
                  label="text"
                  placeholder="Description"
                  v-model="description"
                  :class="{
                    'is-invalid':
                      $v.description.$error && $v.description.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </b-form-textarea>

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.description.$dirty"
                >
                  {{ !$v.description.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </modal>
      </div>
    </template>
  </CustomCard>
</template>

<script>
import CustomCard from "../../components/CustomCard.vue";
import Modal from "@/components/custom/Modal.vue";
import { required } from "vuelidate/lib/validators";
import TableActions from "@/components/custom/TableActions";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";
export default {
  components: {
    CustomCard,
    Modal,
    TableActions,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "@type", label: "Type", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
          sortable: false,
        },
      ],
      items: [],
      perPage: 5,
      currentPage: 1,
      loading: true,
      showAddPortModal: false,
      name: "",
      description: "",
      type: "",
    };
  },
  validations: {
    name: { required },
    description: { required },
    type: { required },
  },
  computed: {
    ...mapGetters("porte", ["PORTES"]),
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.$store.dispatch("porte/fetchAllPortes").then(() => {
      this.loading = false;
    });
  },
  methods: {
    addPort() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.type === "Application") {
          this.$store
            .dispatch("porte/createPortApplication", {
              name: this.name,
              description: this.description,
            })
            .then(() => {
              this.showAddPortModal = false;
              this.$store.dispatch("porte/fetchAllPortes").then(() => {
                this.name = "";
                this.description = "";
                Swal.fire("La portée est bien créée !", "", "success");
              });
            });
        } else if (this.type === "Composant") {
          this.$store
            .dispatch("porte/createPortComposant", {
              name: this.name,
              description: this.description,
            })
            .then(() => {
              this.showAddPortModal = false;
              this.$store.dispatch("porte/fetchAllPortes").then(() => {
                Swal.fire("La portée est bien créée !", "", "success");
                this.name = "";
                this.description = "";
              });
            });
        }
      }
    },
    editItem(item) {
      this.$store.dispatch("porte/fetchAllPortes").then(() => {
        this.items = this.items.map((porte) =>
          item.id === porte.id ? { ...porte, editMode: true } : porte
        );
        this.name = item.name;
        this.description = item.description;
        this.type = item["@type"] ? item["@type"].slice(5) : "";
      });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.type === "Application") {
          this.$store
            .dispatch("porte/updatePortApplication", {
              id: item.id,
              name: this.name,
              description: this.description,
            })
            .then(() => {
              this.$store.dispatch("porte/fetchAllPortes").then(() => {
                Swal.fire("La portée est mise à jour !", "", "success");
                this.name = this.description = "";
              });
            });
        } else if (this.type === "Composant") {
          this.$store
            .dispatch("porte/updatePortComposant", {
              id: item.id,
              name: this.name,
              description: this.description,
            })
            .then(() => {
              this.$store.dispatch("porte/fetchAllPortes").then(() => {
                Swal.fire("La portée est mise à jour !", "", "success");
                this.name = this.description = "";
              });
            });
        }
      }
    },
    cancelItem() {
      if (this.name === "" && this.description === "" && this.type === "")
        this.$store.dispatch("porte/fetchAllPortes");
      else this.name = this.description = this.type = "";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("porte/deletePorte", id).then(() => {
            this.$store.dispatch("porte/fetchAllPortes");
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
    filterProtee(value) {
      this.items = this.PORTES.filter(
        (status) =>
          status.name.toLowerCase().includes(value.toLowerCase()) ||
          status.description.toLowerCase().includes(value.toLowerCase()) ||
          status["@type"].toLowerCase().includes(value.toLowerCase())
      );
    },
  },
  watch: {
    PORTES() {
      this.items = this.PORTES;
    },
  },
};
</script>
