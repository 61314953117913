<template>
  <section class="section position-relative">
    <div class="mt-4 row">
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-8
          px-0
          pr-md-2
          py-md-0 py-2
        "
      >
        <PortesTable />
      </div>
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-4
          pl-md-2
          px-0
          py-md-0 py-2
        "
      >
        <CurrenciesTable />
      </div>
    </div>

    <div class="mt-md-4 row">
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-6
          px-0
          pr-md-2
          py-md-0 py-2
        "
      >
        <StatusTable />
      </div>
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-6
          pl-md-2
          px-0
          py-md-0 py-2
        "
      >
        <TypesTable />
      </div>
    </div>

    <div class="mt-md-4 row">
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-6
          px-0
          pr-md-2
          py-md-0 py-2
        "
      >
        <RefPhasesTable />
      </div>
      <div
        class="
          flex-wrap
          justify-content-between
          col-12 col-md-6
          pl-md-2
          px-0
          py-md-0 py-2
        "
      >
        <EditeursTable />
      </div>
    </div>

    <div class="mt-md-4 row">
      <div
        class="
          flex-wrap
          justify-content-between
          col-12  
          px-0
          
          py-md-0 py-2
        "
      >
        <LigneMetierTable />
      </div>
    </div>
  </section>
</template>
<script>
import StatusTable from "./components/StatusTable.vue";
import PortesTable from "./components/PortesTable.vue";
import TypesTable from "./components/TypesTable.vue";
import CurrenciesTable from "./components/CurrenciesTable.vue";
import RefPhasesTable from "./components/RefPhasesTable.vue";
import EditeursTable from "./components/EditeursTable.vue";
import LigneMetierTable from "./components/LigneMetierTable.vue";
export default {
  components: {
    StatusTable,
    PortesTable,
    TypesTable,
    CurrenciesTable,
    RefPhasesTable,
    EditeursTable,
    LigneMetierTable,
  },
};
</script>
